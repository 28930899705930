import React from "react"

const SearchIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.89187 0C4.44231 0 0.00878906 4.43352 0.00878906 9.88308C0.00878906 15.3326 4.44231 19.7662 9.89187 19.7662C15.3414 19.7662 19.7749 15.3326 19.7749 9.88308C19.7749 4.43352 15.3414 0 9.89187 0ZM9.89187 17.8926C5.4754 17.8926 1.88236 14.2995 1.88236 9.88308C1.88236 5.46661 5.4754 1.87357 9.89187 1.87357C14.3083 1.87357 17.9014 5.46661 17.9014 9.88308C17.9014 14.2995 14.3083 17.8926 9.89187 17.8926ZM23.7161 23.7256C23.5332 23.9085 23.2935 24 23.0537 24C22.8139 24 22.5742 23.9085 22.3913 23.7256L18.1992 19.5335C17.8333 19.1676 17.8333 18.5745 18.1992 18.2087C18.565 17.8429 19.1582 17.8428 19.524 18.2087L23.7161 22.4008C24.0819 22.7667 24.0819 23.3598 23.7161 23.7256Z"
        fill="white"
      />
    </svg>
  )
}

export default SearchIcon
