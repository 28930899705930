import React, { useState } from "react"
import { graphql } from "gatsby"

// utils
import data from "../assets/data.json"

// components
import FAQHero from "../components/Faq/FAQHero"
import Accordion from "../components/Faq/Accordion"
import Layout from "../components/layout"
import { Trans, useTranslation } from "react-i18next"
import { pageDirection } from "../helpers/utils"
import Seo from "../components/seo"

const FAQ = () => {
  const [searchData, setSearchData] = useState(data.faq)
  const [faqtitle, setFaqTitle] = useState("")
  const dir = pageDirection()
  const { t } = useTranslation()
  const search = e => {
    const keyword = e.target.value
    if (keyword !== "") {
      const results = data.faq.filter(question => {
        return (
          t(question.title)
            .trim()
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          t(question.title).toLowerCase().startsWith(keyword.toLowerCase())
        )
      })
      // If there is current input value then filter based on the value
      setSearchData(results)
    } else {
      setSearchData(data.faq)
      // If the text field is empty, show all users
    }

    setFaqTitle(keyword)
  }

  return (
    <Layout
      page="faq"
      footerBgVariant="light"
      currentpage={t("termsOfUseLabel")}
    >
      <Seo title="Learn more about tax free shopping and Samar" />
      <FAQHero dir={dir} search={search} faqtitle={faqtitle}></FAQHero>
      <div
        dir={dir}
        className={
          searchData.length === 0
            ? "space-bottom-no-search"
            : "space-bottom-on-search"
        }
      >
        {searchData.length === 0 ? (
          <h1 className="alert-warning  d-flex" role="alert">
            <Trans>noSearchResultsBody</Trans>
          </h1>
        ) : (
          searchData.map(searches => {
            return <Accordion {...searches} key={searches.id}></Accordion>
          })
        )}
      </div>
    </Layout>
  )
}

export default FAQ

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
