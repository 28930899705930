import React, { useState } from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// styles
import "./Faq.css"

// icons
import ArrowDownIcon from "../Icons/ArrowDownIcon"
import ArrowUpIcon from "../Icons/ArrowUpIcon"

const Accordion = ({ title, content }) => {
  const [isIopen, setIsopen] = useState(false)
  const { t } = useTranslation()
  const handleCose = () => {
    setIsopen(prev => {
      return !prev
    })
  }
  return (
    <div className="accordion-bg">
      <div className="container-lg accordion-space-up">
        <div className="accordion-title-container" onClick={handleCose}>
          <h3 className="accordion-title-bar">
            <Trans>{title}</Trans>
          </h3>
          <span>{isIopen ? <ArrowUpIcon /> : <ArrowDownIcon />}</span>
        </div>
        <div
          className={`accordion-content ${isIopen && "active-accordion"}`}
          dangerouslySetInnerHTML={{ __html: t(content) }}
        ></div>
        <div className="underline-accordion" id="accordion-ruler"></div>
      </div>
    </div>
  )
}

export default Accordion
