import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// components
import "./Faq.css"

// styles
import SearchIcon from "./Icons/SearchIcon"

const FAQHero = ({ dir, search, inputRef, handleBlur, faqtitle }) => {
  const { t } = useTranslation()
  return (
    <div dir={dir} className="container-lg faq-bg-container">
      <div className="hero-item-container">
        <h1 className="faq-hero-text d-flex">
          <Trans>faqsLabel</Trans>
        </h1>
        <form onSubmit={e => e.preventDefault()}>
          <input
            ref={inputRef}
            type="text"
            value={faqtitle}
            placeholder={t("searchBarInnerText")}
            onBlur={handleBlur}
            onChange={e => {
              search(e)
            }}
          />
          <SearchIcon className="svg-icon-position" />
        </form>
      </div>
    </div>
  )
}

export default FAQHero
